import React from "react"
import { graphql } from "gatsby"
import { Sticky } from "semantic-ui-react"
import { useWindowScroll } from "react-use"
import styled from "styled-components"
import "semantic-ui-css/components/sticky.min.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import { RichText } from "../api/contentful"
import TeamMembers from "../components/team-members"
import {
  NavLink,
  Section,
  Pagehead,
  PageTitle,
  Breadcrumbs,
  GridContainer,
} from "../components/shared"

const StickyWrapper = styled.div`
  position: relative;
  z-index: 0;
`

const Departments = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  @media only screen and (max-width: 800px) {
    display: none; /* TODO: */
  }
`

const Department = styled.li`
  font-size: 1rem;
  position: relative;
  padding: 0.4em 0 0.2em;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5em;
    border-top: 2px solid;
    pointer-events: none;
  }
`

const TeamPage = ({ data }) => {
  const pageData = data.contentfulTeamPage

  const { departments } = data.contentfulCompany

  const stickyContainerRef = React.useRef()

  // number of departments is guaranteed to be constant
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const departmentRefs = departments.map(() => React.useRef())

  const { y } = useWindowScroll()

  const [activeDepartmentIndex, setActiveDepartmentIndex] = React.useState()
  React.useEffect(() => {
    const departmentOffsets = departmentRefs.map(
      departmentRef => departmentRef.current?.offsetTop ?? Infinity
    )

    const headerOffset = 200
    const activeDepartmentIndex = departmentOffsets.reduceRight(
      (foundIndex, currentOffset, currentIndex) => {
        if (foundIndex) {
          return foundIndex
        }
        if (y + headerOffset > currentOffset) {
          return currentIndex
        }
        return undefined
      },
      undefined
    )
    setActiveDepartmentIndex(activeDepartmentIndex)
  }, [y, departmentRefs])

  return (
    <Layout>
      <SEO title={pageData.title} description={pageData.metaDescription} />
      <Pagehead>
        <PageTitle>
          <RichText node={pageData.pageTitle} unwrapParagraphs />
        </PageTitle>
        <Breadcrumbs nodes={[{ title: "Team" }]} />
      </Pagehead>
      <Section>
        <GridContainer>
          <StickyWrapper>
            <Sticky context={stickyContainerRef} offset={140}>
              <Departments>
                {departments.map((department, index) => (
                  <Department key={department.name}>
                    <NavLink
                      to={`#${department.name
                        .toLowerCase()
                        .replace(/,?\s+/, "-")}`}
                      className={
                        index === activeDepartmentIndex ? "active" : undefined
                      }
                    >
                      {department.name}
                    </NavLink>
                  </Department>
                ))}
              </Departments>
            </Sticky>
          </StickyWrapper>

          <div ref={stickyContainerRef}>
            <TeamMembers
              departments={departments}
              departmentRefs={departmentRefs}
            />
          </div>
        </GridContainer>
      </Section>
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  query TeamPageQuery {
    contentfulTeamPage {
      title
      metaDescription
      pageTitle {
        json
      }
    }

    contentfulCompany {
      departments {
        name
        members {
          firstName
          lastName
          prefixTitle
          suffixTitle
          roles
          emailAddress
          phoneNumber
          photo {
            file {
              url
            }
          }
          article {
            title
          }
        }
      }
    }
  }
`

export default TeamPage
