import React from "react"
import styled from "styled-components"

import { UnstyledLink, Link, CallToAction } from "./shared"
import {
  formatAcademicTitle,
  formatPhoneNumber,
  narrowNoBreakSpace,
} from "../util/typography"

const Text = styled.div`
  flex: 2;
  padding: 0 1em;

  @media only screen and (max-width: 480px) {
    padding: 1em 0;
  }
`

const Photo = styled.img`
  width: calc(20em / 1.48);
  height: 20em;

  @media only screen and (max-width: 480px) {
    width: 80%;
    height: calc(80% * 1.48);
  }
`

const PhotoPlaceholder = styled(Photo)`
  background-color: ${props => props.theme.teamVacancyPlaceholderColor};
`

const List = styled.ul`
  margin: 0 auto;
  padding: 0 1em;

  @media only screen and (max-width: 666px) {
    padding: 0;
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: flex-end;
  margin: 0 0 3em 0;
  scroll-margin-top: 100px;

  &:nth-child(even) {
    flex-direction: row-reverse;

    ${Text} {
      text-align: right;
    }
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;

    &:nth-child(odd) {
      ${Photo} {
        align-self: flex-start;
      }
      ${Text} {
        align-self: flex-start;
      }
    }

    &:nth-child(even) {
      flex-direction: column;
    }
  }
`
const Name = styled.h3`
  margin: 0.1em 0;
  font-size: 1.8rem;
  line-height: 1.6rem;
`

const Title = styled.span`
  display: block;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: normal;
`

const Roles = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Role = styled.li`
  display: inline-block;
  font-style: italic;

  &:not(:last-child)::after {
    content: ", ";
    white-space: pre;
  }
`

const ContactInformation = styled.address`
  margin: 0.7em 0 0 0;
  font-style: normal;
`

const PhoneNumber = styled.div``

const EmailAddress = styled.div``

const TeamMembers = ({ departments, departmentRefs }) => {
  return (
    <List>
      {departments.map((department, departmentIndex) =>
        (department.members || []).flatMap((teamMember, index, members) => {
          const forwardedRef =
            index === 0 ? departmentRefs[departmentIndex] : undefined
          const departmentSlug = department.name
            .toLowerCase()
            .replace(/,?\s+/, "-")

          return [
            <TeamMember
              {...teamMember}
              id={index === 0 ? departmentSlug : undefined}
              forwardedRef={forwardedRef}
              key={teamMember.emailAddress}
            />,
            departmentIndex === Math.floor(departments.length / 2) &&
            index === Math.floor(members.length / 2) ? (
              <Vacancy
                title="Freie Stelle"
                id={`freie-stelle-${departmentSlug}`}
                forwardedRef={forwardedRef}
                key={`freie-stelle-${departmentSlug}`}
              />
            ) : null,
          ]
        })
      )}
    </List>
  )
}

const Vacancy = ({ title, id, forwardedRef }) => {
  const phoneNumber = `02266${narrowNoBreakSpace}/${narrowNoBreakSpace}901890${narrowNoBreakSpace}-${narrowNoBreakSpace}0`
  const emailAddress = "jobs@holzbauplanung.de"

  return (
    <ListItem id={id} ref={forwardedRef} key={emailAddress}>
      <PhotoPlaceholder as="div" />
      <Text>
        <Name>{title}</Name>
        <ContactInformation>
          <PhoneNumber>{phoneNumber}</PhoneNumber>
          <EmailAddress>
            <Link to={`mailto:${emailAddress}`}>{emailAddress}</Link>
          </EmailAddress>
        </ContactInformation>
        <CallToAction
          to={`/jobs`}
          style={{ marginTop: "1em", fontSize: "1rem" }}
        >
          erfahre mehr
        </CallToAction>
      </Text>
    </ListItem>
  )
}

const TeamMember = ({
  photo,
  prefixTitle,
  suffixTitle,
  firstName,
  lastName,
  roles,
  phoneNumber,
  emailAddress,
  article,
  id,
  forwardedRef,
}) => {
  const name = `${firstName} ${lastName}`

  return (
    <ListItem id={id} ref={forwardedRef} key={emailAddress}>
      <Photo src={photo.file.url} alt={`Portrait von ${name}`} />
      <Text>
        <Name>
          {prefixTitle && (
            <>
              <Title>{formatAcademicTitle(prefixTitle)}</Title>{" "}
            </>
          )}
          {name}
          {suffixTitle && (
            <>
              {" "}
              <Title>{formatAcademicTitle(suffixTitle)} </Title>
            </>
          )}
        </Name>
        {roles && (
          <Roles>
            {roles.map(role => (
              <Role key={role}>{role}</Role>
            ))}
          </Roles>
        )}
        <ContactInformation>
          <PhoneNumber>
            <UnstyledLink
              to={`tel:${phoneNumber
                .replace(/[\s-]/g, "")
                .replace(/^00/, "+")
                .replace(/^0/, "+49")}`}
            >
              {formatPhoneNumber(phoneNumber)}
            </UnstyledLink>
          </PhoneNumber>
          <EmailAddress>
            <Link to={`mailto:${emailAddress}`}>{emailAddress}</Link>
          </EmailAddress>
        </ContactInformation>
        {article && article.length > 0 && (
          <CallToAction
            to={`/magazin/${name.toLowerCase().replace(" ", "-")}`}
            style={{ marginTop: "1em", fontSize: "1rem" }}
          >
            Artikel
          </CallToAction>
        )}
      </Text>
    </ListItem>
  )
}

export default TeamMembers
